import { createReducer, on } from '@ngrx/store';
import * as functionActions from '../actions/function.actions';
import { FunctionStateInterface } from '@app/shared/types/function.state.interface';

export const initialState: FunctionStateInterface = {
    isLoading: false,
	functions: [],
	error: null,
	pageNumber: 0,
    rowsPerPage: 10,
    totalRecords: 0
};

export const functionReducer = createReducer(
    { ...initialState },
    on(functionActions.getFunctions, (state) => ({
		...state,
		isLoading: true 
	})),
	on(functionActions.getFunctionsSuccessfully, (state, action) => ({
		...state,
		isLoading: false,
		functions: action.paginatedResult.entities,
		totalRecords: action.paginatedResult.totalRecords,
		pageNumber: action.paginatedResult.pageNumber,
		rowsPerPage: action.paginatedResult.rowsPerPage
	})),
	on(functionActions.getFunctionsFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),
	on(functionActions.createFunction, (state) => ({
		...state,
		isLoading: true
	  })),
	  on(functionActions.createFunctionSuccesfully, (state) => ({
		...state,
		isLoading: false
	  })),
	  on(functionActions.createFunctionFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	  })),

);
	