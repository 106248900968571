import { Component } from '@angular/core'
import { Store, select } from '@ngrx/store';
import { SettingStateInterface } from './shared/types/setting-state.interface';
import * as settingAction from '@app-store/actions/setting.actions';
import * as functionActions from '@app-store/actions/function.actions'
import { FunctionStateInterface } from './shared/types/function.state.interface';
import { AuthService } from './authentication/auth.service';
import { LoaderService } from './shared/services/loader.service';
import { Observable } from 'rxjs';
import { MaintanenceStateInterface } from './shared/types/maintenance.interface';
import { maintenaceStatusSelector } from './store/selectors/maintenance.selector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  title = 'ENTSO-E CIO/LIO';
  previousUrl: string;
  currentUrl: string;

  isMaintenance$: Observable<boolean>

  constructor(private settingStore: Store<SettingStateInterface>,
    private functionStore: Store<FunctionStateInterface>,
    private authService: AuthService,
    public loaderService: LoaderService,
    private maintenanceStore: Store<MaintanenceStateInterface>
    ) {
      this.isMaintenance$ = this.maintenanceStore.pipe(select(maintenaceStatusSelector))
    }
  
  ngOnInit() {
    this.authService.isLoggedIn().then(isLoggedIn => {
      if (isLoggedIn) {
        this.authService.updateUserStore();
        this.settingStore.dispatch(settingAction.getSettings());
        this.functionStore.dispatch(functionActions.getFunctions({tableOptions: undefined}));
      }
    })
  }
}
