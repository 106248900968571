import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { Observable, catchError, throwError } from 'rxjs'
import { FunctionInterface } from '../types/function.interface'
import { TableFilteringSortingOptions } from '../types/export-options-columns.interface'
import { PaginatedResult } from '../types/paginated-result.interface'
import { CreateFunctionCommand } from '../types/commands/createFunctionCommand.model'
import { UpdateFunctionCommand } from '../types/commands/updateFunctionCommand.model'

@Injectable()
export class FunctionService {
  constructor(private http: HttpClient) {}

  getAllFunctions(tableOptions: TableFilteringSortingOptions | undefined): Observable<PaginatedResult<FunctionInterface>> {
    return this.http.get<PaginatedResult<FunctionInterface>>('functions/', { params: this.ToHttpParams(tableOptions) }).pipe(catchError(this.handleError))
  }

  createFunction(func: CreateFunctionCommand) {
    return this.http.post('functions/', func).pipe(catchError(this.handleError))
  }

  updateFunction(func: UpdateFunctionCommand){
    return this.http.put('functions/',func).pipe(catchError(this.handleError))
  }

  private ToHttpParams(request: any): HttpParams {
    let httpParams = new HttpParams()
    if(request != undefined && request != null){
      Object.keys(request).forEach(function (key) {
        httpParams = httpParams.append(key, request[key])
      })
    }

    return httpParams
  }

  private handleError(error: HttpErrorResponse) {
    var errorMessage = ''
    if (error.status === 0) {
      errorMessage = 'Could not connect to the backend API. Please contact the system administrator'
    } else {
      errorMessage = error.error
    }
    return throwError(() => new Error(errorMessage))
  }
}
