<div class="p-24px bg-white border-round">
    <div class="flex justify-content-between mb-6">
      <h1 class="mb-3 h3 text-secondary">Functions</h1>
    </div>
    
    <app-create-function #modalCreateFunction></app-create-function>
    <app-update-function #modalUpdateFunction></app-update-function>

    <app-table
    [values]="functions"
    [settingType]="settingType"
    selectionMode="false"
    styleClass="p-datatable-gridlines"
    tableStyleClass="table-layout-fixed border-round"
    [totalRecords]="totalRecords"
    [firstRow]="firstRow"
    (onPageChange)="onPageChange($event)"
    (onFunctionEdit)="OpenUpdateFunctionModal($event)"
    [functionStatusList]="functionStatusList"
    [functionStatusFilterOptions]="functionStatusFilterOptions"
    [eicCodeTypesOptions]="eicCodeTypesOptions"
    [filterOptions]="tableFilterOrSortOptions"
  >
    <div leftSideContainer class="flex align-items-center">
      <p-button label="Add new function" icon="icon icon--plus" (onClick)="OpenCreateFunctionModal()"></p-button>
    </div>
  </app-table>
  </div>
  