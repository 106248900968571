<p-table
  #dt
  [columns]="displayedColumns"
  [value]="values"
  [tableStyleClass]="tableStyleClass || 'table-layout-fixed'"
  [styleClass]="styleClass || ''"
  [paginator]="paginator || true"
  [rows]="nrOfRows || 10"
  [totalRecords]="totalRecords"
  [currentPageReportTemplate]="currentPageReportTemplate || 'Total {totalRecords} items'"
  [showCurrentPageReport]="showCurrentPageReport || true"
  [rowsPerPageOptions]="rowsPerPageOptions || [10, 25, 50]"
  [showFirstLastIcon]="showFirstLastIcon || false"
  [responsiveLayout]="responsiveLayout || 'scroll'"
  [selectionMode]="selectionMode || ''"
  (selectionChange)="onRowChangeMethod($event)"
  [scrollable]="true"
  [(selection)]="selectedRows"
  [lazy]="true"
  [lazyLoadOnInit]="false"
  [first]="firstRow"
  (onLazyLoad)="onPageChangeMethod($event)"
  [dataKey]="isUsersTable ? 'email' : 'id'"
  (onRowSelect)="onRowSelectMethod($event)"
  (onRowUnselect)="onRowUnselectMethod($event)"
  paginatorDropdownAppendTo="body"
  [sortField]="defaultSortField != null ? defaultSortField.field : ''"
  [sortOrder]="defaultSortField != null ? defaultSortField.direction : ''"
>
  <ng-template pTemplate="caption" *ngIf="showCaption">
    <div [class]="isRequestTable ? 'flex justify-content-between' : 'd-flex flex-row-reverse'">
      <div class="flex flex-inline align-items-center mb-3">
        <ng-content select="[handleRowSelectionButtons]"></ng-content>
        <ng-content select="[leftSideContainer]"></ng-content>

        <div *ngIf="export" class="flex align-items-center">
          <app-export-list
            [exportOptionColumns]="exportOptionColumns"
            [exportPlace]="exportPlace"
            [selectedExportTypes]="selectedExportTypes"
            [selectedRows]="selectedRows"
            [hideExportSelection]="hideExportSelection"
            [exportBtnDisabled]="exportBtnDisabled"
            [displayedColumns]="displayedColumns"
            [exportOptions]="exportOptions"
          ></app-export-list>
        </div>
        <div *ngIf="download" class="flex align-items-center">
          <app-download [selectedRow]="selectedRows"> </app-download>
        </div>
        <div *ngIf="tpHistory">
          <app-tp-history></app-tp-history>
        </div>
        <div *ngIf="isMyRequestPage" class="flex align-items-center">
          <p-toolbar styleClass="w-fit">
            <span class="text-secondary">New request</span>
            <p-button
              (onClick)="onCreateMethod()"
              >Create</p-button
            >
        
            <p-button
              (onClick)="onXmlUploadMethod()" [outlined]="true" severity="secondary"
              >Upload XML</p-button
            >
          </p-toolbar>
        </div>
      </div>

      <div class="flex justify-content-end mb-4" *ngIf="visibilityColumnsHeader">
        <p-overlayPanel
          #columnToggle
          styleClass="p-overlaypanel--filter columnsSelector"
          [dismissable]="false"
          [showCloseIcon]="true"
          (onHide)="onHideSelectColumnsMethod()"
        >
          <p-listbox
            [style]="{ width: '180px' }"
            [options]="allTableHeaders"
            [(ngModel)]="_selectedColumns"
            [styleClass]="visibilityColumnsHeaderObj.styleClass || 'p-listbox'"
            [optionLabel]="visibilityColumnsHeaderObj.optionLabel || 'header'"
            [multiple]="visibilityColumnsHeaderObj.multiple || true"
            [metaKeySelection]="visibilityColumnsHeaderObj.metaKeySelection || false"
            [checkbox]="visibilityColumnsHeaderObj.checkbox || true"
            [showToggleAll]="visibilityColumnsHeaderObj.showToggleAll || false"
            [optionDisabled]="visibilityColumnsHeaderObj.optionDisabled || 'activeStatus'"
          />
          <div class="p-column-filter-buttonbar" style="float: right">
            <p-button
              (click)="columnToggle.toggle($event)"
              styleClass="p-button-sm p-ghost-button"
              label="reset"
              (onClick)="resetSelectedColumns()"
            />
            <p-button
              (click)="columnToggle.toggle($event)"
              (onClick)="saveSelectedColumns()"
              styleClass="p-button-sm"
              label="OK"
            />
          </div>
        </p-overlayPanel>
        <p-button
          label="Column visibility"
          [outlined]="true"
          severity="secondary"
          icon="icon icon--eye"
          (click)="columnToggle.toggle($event)"
        />
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 4rem" *ngIf="checkBoxMultiSelect">
        <p-tableHeaderCheckbox *ngIf="enableSelectAll"></p-tableHeaderCheckbox>
      </th>
      <th *ngIf="expandableRows" style="width: 5rem"></th>
      <th
        *ngFor="let col of columns"
        [style]="'width:' + col.minWidth + 'px'"
        [psortablecolumn]="defaultSortField != null ? defaultSortField.field : ''"
      >
        <div class="flex justify-content-between align-items-center">
          {{ col.header }}
          <div class="flex align-items-center gap-1" *ngIf="col.field == 'requestActionObj'">
            <p-sortIcon
                *ngIf="col.sort"
                [pSortableColumn]="col.field"
                [field]="col.field"
                [sortOrder]="1"
                class="p-sortable-column-icon-wrapper p-link icon icon--sort w-1rem h-1rem"
              />
            <p-columnFilter
              *ngIf="col.filter"
              [field]="col.field"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="requestActionFilterOptions"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </div>

          <div class="flex align-items-center gap-1" *ngIf="col.field == 'statusObj'">
            <p-sortIcon
                *ngIf="col.sort"
                [pSortableColumn]="col.field"
                [field]="col.field"
                [sortOrder]="1"
                class="p-sortable-column-icon-wrapper p-link icon icon--sort w-1rem h-1rem"
              />
            <p-columnFilter
              *ngIf="col.filter"
              [field]="col.field"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="statusFilterOptions"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </div>

          <div class="flex align-items-center gap-1" *ngIf="col.field == 'functionStatusObj'">
            <p-columnFilter
              *ngIf="col.filter"
              type="text"
              [field]="col.field"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="functionStatusFilterOptions"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </div>

          <div class="flex align-items-center gap-1" *ngIf="col.field == 'eicCodeTypeObj'">
            <p-sortIcon
                *ngIf="col.sort"
                [pSortableColumn]="col.field"
                [field]="col.field"
                [sortOrder]="1"
                class="p-sortable-column-icon-wrapper p-link icon icon--sort w-1rem h-1rem"
              />
            <p-columnFilter
              *ngIf="col.filter"
              [field]="col.field"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="eicCodeTypesOptions"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                  class="eicCodeTypeDropdown"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </div>

          <div class="flex align-items-center gap-1" *ngIf="col.field == 'sourceObj'">
            <p-sortIcon
                *ngIf="col.sort"
                [pSortableColumn]="col.field"
                [field]="col.field"
                [sortOrder]="1"
                class="p-sortable-column-icon-wrapper p-link icon icon--sort w-1rem h-1rem"
              />
            <p-columnFilter
              *ngIf="col.filter"
              [field]="col.field"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="requestSourceFilterOptions"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </div>

          <div class="flex align-items-center gap-1" *ngIf="col.field == 'state'">
            <p-sortIcon
              *ngIf="col.sort"
              [pSortableColumn]="col.field"
              [field]="col.field"
              [sortOrder]="1"
              class="p-sortable-column-icon-wrapper p-link icon icon--sort w-1rem h-1rem"
            />
            <p-columnFilter
              *ngIf="col.filter"
              [field]="col.field"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="tpHistoryState"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </div>

          <div class="flex align-items-center gap-1" *ngIf="col.field == 'result'">
            <p-sortIcon
            *ngIf="col.sort"
            [pSortableColumn]="col.field"
            [field]="col.field"
            [sortOrder]="1"
            class="p-sortable-column-icon-wrapper p-link icon icon--sort w-1rem h-1rem"
          />
            <p-columnFilter
              *ngIf="col.filter"
              [field]="col.field"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="tpHistoryResult"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </div>

          <div class="flex align-items-center gap-1" *ngIf="checkIfFieldIsDate(col.field)">
            <p-sortIcon
              *ngIf="col.sort"
              [pSortableColumn]="col.field"
              [field]="col.field"
              [sortOrder]="1"
              class="p-sortable-column-icon-wrapper p-link icon icon--sort w-1rem h-1rem"
            />

            <p-columnFilter
              *ngIf="col.filter"
              [field]="col.field"
              display="menu"
              class="flex"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
              [showApplyButton]="false"
              [showClearButton]="false"
              matchMode="'customCreatedDateFilter'"
            >
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold">Filter on {{ col.header }}</span>
                </div>
              </ng-template>

              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-calendar
                  [ngModel]="value"
                  (onSelect)="onDateSelect($event, col.field)"
                  appendTo="body"
                  selectionMode="range"
                  [readonlyInput]="true"
                  (onClearClick)="onDateClear($event, col.field)"
                  [showButtonBar]="true"
                >
                </p-calendar>
              </ng-template>
            </p-columnFilter>
          </div>

          <div *ngIf="col.field == 'availableMarketsList'">
            <p-columnFilter
              *ngIf="col.filter"
              type="text"
              [field]="col.field"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
              [selectionLimit]="1"
            >
              <ng-template pTemplate="filtericon">
                <i class="icon icon--filter w-1rem h-1rem"></i>
              </ng-template>
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="availableMarketsList"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                >
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </div>

          <div class="flex align-items-center gap-1" *ngIf="col.field == 'functions'">
            <p-sortIcon
                *ngIf="col.sort"
                [pSortableColumn]="col.field"
                [field]="col.field"
                [sortOrder]="1"
                class="p-sortable-column-icon-wrapper p-link icon icon--sort w-1rem h-1rem"
              />
            <p-columnFilter
              *ngIf="col.filter"
              [field]="col.field"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="allFunctions"
                placeholder="Any"
                (onChange)="filter($event.value)"
                optionLabel="name"
                (onSelectAllChange)="onSelectAllChange(filter, $event)"
                [selectAll]="selectAll"
                (onFilter)="setFilteredFunctions($event)"
              >
                <ng-template let-option pTemplate="item">
                  <div class="inline-block vertical-align-middle">
                    <span class="ml-1 mt-1">{{ option.name }}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
          </div>

          <div class="flex align-items-center gap-1" *ngIf="col.field == 'duration'">
            <p-sortIcon
            *ngIf="col.sort"
            [pSortableColumn]="col.field"
            [field]="col.field"
            [sortOrder]="1"
            class="p-sortable-column-icon-wrapper p-link icon icon--sort w-1rem h-1rem"
          />
            <p-columnFilter
              *ngIf="col.filter"
              [field]="col.field"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
              [showClearButton]="false"
            >
              <ng-template pTemplate="filter" let-filter="filterCallback">
                <div class="mt-1">
                <label for="timeFrom">From:</label>
                <p-calendar
                [(ngModel)]="fromFilterValue"
                inputId="timeFrom"
                [timeOnly]="true"
                [showSeconds]="true"
                [defaultDate]="defaultDate"
                (onClear)="toFilterValue = undefined; filter(null)"
                [showClear]="true"
                (onClose)="fromFilterValue ? filter(fromFilterValue) : filter(toFilterValue)">
              </p-calendar>
            </div>

              <div class="mt-3">
              <label for="timeTo" >To:</label>
                <p-calendar
                [(ngModel)]="toFilterValue"
                inputId="timeTo"
                [timeOnly]="true"
                [showSeconds]="true"
                [defaultDate]="defaultDate"
                (onClear)="fromFilterValue = undefined; filter(null)"
                [showClear]="true"
                (onClose)="toFilterValue ? filter(toFilterValue) : filter(fromFilterValue)">
              </p-calendar>
            </div>
              </ng-template>
            </p-columnFilter>
          </div>

          <div
            *ngIf="
              col.field != 'requestActionObj' &&
              col.field != 'statusObj' &&
              col.field != 'functionStatusObj' &&
              col.field != 'sourceObj' &&
              !checkIfFieldIsDate(col.field) &&
              col.field != 'eicCodeTypeObj' &&
              col.field != 'availableMarketsList' &&
              col.field != 'functions' &&
              col.field != 'state' &&
              col.field != 'result' &&
              col.field != 'duration'
            "
          >
            <div class="flex align-items-center gap-1">
              <p-sortIcon
                *ngIf="col.sort"
                [pSortableColumn]="col.field"
                [field]="col.field"
                [sortOrder]="1"
                class="p-sortable-column-icon-wrapper p-link icon icon--sort w-1rem h-1rem"
              />
              <p-columnFilter
                *ngIf="col.filter"
                type="text"
                class="flex"
                [field]="col.field"
                display="menu"
                matchMode="contains"
                [showMatchModes]="false"
                [showOperator]="false"
                [showAddButton]="false"
              >
                <ng-template pTemplate="filtericon">
                  <i class="icon icon--search w-1.2rem h-1.2rem"></i>
                </ng-template>
              </p-columnFilter>
            </div>
          </div>
        </div>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-value let-columns="columns" let-expanded="expanded">
    <tr [pSelectableRow]="value">
      <td *ngIf="checkBoxMultiSelect">
        <p-tableCheckbox [pSelectableRow]="value" [value]="value"></p-tableCheckbox>
      </td>
      <td *ngIf="expandableRows">
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="value"
          (click)="onRowExpand.emit(value)"
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="expanded ? 'icon icon--angle-down' : 'icon icon--chevron-right'"
        ></button>
      </td>
      <td *ngFor="let col of columns" [style]="'width:' + col.minWidth + 'px'">
        <div *ngIf="col.field == 'requestActionObj'">
          <p-button
            [value]="value.requestAction"
            [label]="requestActionsList[value.requestAction].label"
            styleClass="p-button-plain p-button-outlined capitalize"
            [outlined]="true"
            severity="secondary"
            [icon]="'icon icon--' + requestActionsList[value.requestAction].icon"
          />
        </div>

        <div *ngIf="col.field == 'statusObj'">
          <p-tag
            [severity]="statusList[value.status].icon"
            [value]="statusList[value.status].label"
          />
        </div>

        <div *ngIf="col.field == 'functionStatusObj'">
          <p-tag
            [severity]="functionStatusList[value.status].icon"
            [value]="functionStatusList[value.status].label"
          />
        </div>

        <div *ngIf="col.field == 'sourceObj'">
          {{ value.sourceObj.label }}
        </div>

        <div *ngIf="col.field == 'eicCodeTypeObj'">
          {{ value.eicCodeTypeObj.value }}
        </div>

        <div *ngIf="col.field == 'availableMarketsList'">
          <div class="flex align-items-center gap-3">
            <img
              *ngIf="value.isElectricity"
              [src]="'/assets/img/lio-market/electricity.svg'"
              [alt]="'electricity'"
            />
            <img *ngIf="value.isGas" [src]="'/assets/img/lio-market/gas.svg'" [alt]="'gas'" />
          </div>
        </div>

        <div *ngIf="col.field == 'lioAction'" class="table-buttons">
          <p-button
            label="Details"
            [routerLink]="'/lio/' + value.lioNumber"
            icon="icon icon--eye"
            styleClass="p-button-outlined p-button-secondary mr-3"
            [outlined]="true"
            severity="secondary"
          ></p-button>
          <p-button
            label="Impersonate"
            (onClick)="impersonateLioMethod(value)"
            styleClass="p-button-outlined p-button-secondary"
            [outlined]="true"
            severity="secondary"
          ></p-button>
        </div>

        <div *ngIf="col.field == 'functionDetail'" class="table-buttons">
          <p-button
            label="Edit"
            (onClick)="editFunction(value)"
            icon="icon icon--edit"
            styleClass="p-button-outlined p-button-secondary mr-3"
            [outlined]="true"
            severity="secondary"
          ></p-button>
        </div>

        <div *ngIf="col.field == 'functions' && value.functions.length > 0">
          {{ functionsToString(value.functions) }}
        </div>

        <div>
          {{ dateToCET(col.field, col.cesTime, value) }}
        </div>

        <div
          class="ellipsis"
          *ngIf="
            col.field != 'requestActionObj' &&
            col.field != 'statusObj' &&
            col.field != 'functionStatusObj' &&
            col.field != 'sourceObj' &&
            !checkIfFieldIsDate(col.field) &&
            col.field != 'lioAction' &&
            col.field != 'functionDetail' &&
            col.field != 'eicCodeTypeObj' &&
            col.field != 'availableMarketsList' &&
            col.field != 'functions'
          "
        >
          {{ value[col.field] }}
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td class="noRecordsFound" [attr.colspan]="columns.length">No records found</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-eic dataKey="id">
    <div class="card-container">
      <div *ngFor="let version of eic.versions" class="card col-12 p-0">
        <div *ngIf="isLio10 || fromLioEic; else other_version_component">
          <a class="version-link" [routerLink]="version.url">
            <app-version-card [version]="version" />
          </a>
        </div>
        <ng-template #other_version_component>
          <a class="version-link">
            <app-version-card [version]="version" />
          </a>
        </ng-template>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="paginatorpreviouspagelinkicon">
    <i class="icon icon--chevron-left w-2rem h-1.2rem"></i>
  </ng-template>

  <ng-template pTemplate="paginatornextpagelinkicon">
    <i class="icon icon--chevron-right w-2rem h-1.2rem"></i>
  </ng-template>
</p-table>

<style>
  .noRecordsFound {
    text-align: center !important;
  }
</style>
