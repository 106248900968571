import { Component, OnDestroy, OnInit } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { liosSelector } from '../../../store/selectors/lio.selector'
import * as LioActions from '../../../store/actions/lio.actions'
import { LioInterface } from '@app/shared/types/lio.interface'
import { LioStateInterface } from '@app/shared/types/lio-state.interface'
import { pageNumberSelector, rowsPerPageSelector } from '@app/store/selectors/request.selector'
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum'
import { LioUsersInterface } from '@app/shared/types/lio-users.interface'
import { environment } from 'environments/environment'
import { UserInfoStateInterface } from '@app/shared/types/user-info-state.interface'
import { isLio10 } from '@app/store/selectors/user-info.selector'
import { Location } from '@angular/common'

@Component({
  selector: 'app-lio-detail',
  templateUrl: './lio-detail.component.html',
  styleUrls: ['./lio-detail.component.scss']
})
export class LioDetailComponent implements OnInit, OnDestroy {
  lioNumber: number
  lio: LioInterface
  lios$: Observable<LioInterface[]>
  lioSub: Subscription

  users: LioUsersInterface[] = []

  pageNumber: number
  rowsPerPage: number
  pageSub: Subscription
  rowsSub: Subscription

  settingType: number
  totalRecords: number
  sftpConnectionString = environment.sftp

  dataRetrieved: boolean = false;

  isLio10: boolean = false;
  history: string[] = [];

  selectedRows: LioUsersInterface[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<LioStateInterface>,
    private userStore: Store<UserInfoStateInterface>,
    private location: Location
  ) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
    this.userStore.select(isLio10()).subscribe((usr) => (this.isLio10 = usr))

    this.lioNumber = parseInt(this.route.snapshot.paramMap.get('lioNumber')!, 10)
    this.lios$ = this.store.pipe(select(liosSelector))
    this.pageSub = this.store.select(pageNumberSelector).subscribe((page) => {
      this.pageNumber = page
    })
    this.rowsSub = this.store.select(rowsPerPageSelector).subscribe((rows) => {
      this.rowsPerPage = rows
    })
  }

  ngOnInit() {
    this.settingType = SettingsTypes.lioUsers

    this.lioSub = this.lios$.subscribe((data) => {
      if (
        data.length > 0 &&
        data.find((l) => l.lioNumber == this.lioNumber) &&
        this.dataRetrieved
      ) {
        this.lio = data.find((l) => l.lioNumber == this.lioNumber)!
        this.users = this.lio.users
        this.totalRecords = this.lio.users.length
      } else {
        this.store.dispatch(LioActions.getLioByLioNumber({ lioNumber: this.lioNumber }))
        this.dataRetrieved = true
      }
    });


  }
  viewLioRequests() {
    this.router.navigate(['lio', this.lioNumber.toString(), 'requests'])
  }

  goToPrevious() {
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigate(['lio/all'])
    }
  }

  ngOnDestroy(): void {
    this.lioSub?.unsubscribe()
    this.pageSub?.unsubscribe()
    this.rowsSub?.unsubscribe()
  }

  onRowChange(event: any) {
    this.selectedRows = event;
  }

  clearUserSelection(){
    this.selectedRows.length = 0;
    this.selectedRows = [];
  }
}
