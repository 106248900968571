export class TableFilteringSortingOptions {
    lioNumber?: number;
    eicCode?: string;
    longName?: string;
    displayName?: string;
    euVATCode?: string;
    requestAction?: string;
    status?: string;
    functionStatus?: string
    requestDate?: string;
    creationDate?: string;
    deactivationDate?: string;
    updateDate?: string;
    approvalDate?: string;
    approvalReason?: string;
    eicCodeType?: string;
    eicParent?: string;
    responsibleParty?: string;
    acerNumber?: string;
    parentRequestId?: string;
    description?: string;
    functions?: string;
    
    name?: string;
    contactPersonName?: string;
    contactPersonEmail?: string;
    availableMarketsList?: string;

    sortBy?: string;

    getFunctions: boolean;
    pageNumber?: number;
    rowsPerPage?: number;
}
