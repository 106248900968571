<form [formGroup]="requestForm">
    <p-fieldset styleClass="mb-5">
        <div class="form-container">
            <div class="form-item">
                <label>Code Type <span class="text-required">*</span></label>
                <p-dropdown
                  class="eicTypeDropdown"
                  placeholder="Select"
                  dropdownIcon="icon icon--angle-down"
                  [options]="codeFunctionOptions"
                  formControlName="functionCode"
                  [ngClass]="{'err': requestForm.controls['hasBeenSubmitted'].value && 
                  !requestForm.controls['functionCode']?.valid}"
                  styleClass="mt-0"
                ></p-dropdown>
                <span *ngIf="!requestForm.controls['functionCode'].valid && 
                  requestForm.controls['hasBeenSubmitted'].value" 
                  class="p-error"> 
                  A code type must be chosen. 
                </span>
            </div>
            <div class="form-item">
              <label>Name <span class="text-required">*</span></label>
              <input class="w-full" formControlName="functionName"
                [ngClass]="{'err': requestForm.controls['hasBeenSubmitted'].value && 
                !requestForm.controls['functionName']?.valid}" placeholder="Input" pInputText />
                <span *ngIf="requestForm.controls['functionName']?.errors?.['pattern']" class="p-error"> 
                  Each word in the name must start with a capital letter and no numbers or special characters are allowed 
                </span>    
            </div>
            <div *ngIf="isEdit" class="form-item">
              <label>Status <span class="text-required">*</span></label>
                <p-dropdown
                  placeholder="Select"
                  dropdownIcon="icon icon--angle-down"
                  [options]="statusLisOption"
                  formControlName="functionStatus"
                  [ngClass]="{'err': requestForm.controls['hasBeenSubmitted'].value && 
                  !requestForm.controls['functionStatus']?.valid}"
                  styleClass="mt-0"
                ></p-dropdown>
                <span *ngIf="!requestForm.controls['functionStatus'].valid && 
                  requestForm.controls['hasBeenSubmitted'].value" 
                  class="p-error"> 
                  A status must be chosen. 
                </span>
            </div>
            <div class="form-item">
                <label>Definition</label>
                <textarea
                  class="w-full"
                  pInputTextarea
                  placeholder="Leave a note"
                  formControlName="definition"
                ></textarea>
            </div>
        </div>
      </p-fieldset>
</form>