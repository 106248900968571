import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, mergeMap, catchError } from 'rxjs/operators'
import * as functionActions from '../actions/function.actions'
import { MessageService } from 'primeng/api'
import { FunctionService } from '@app/shared/services/function.service'
import { error } from 'console'

@Injectable()
export class FunctionEffect {
  constructor(
    private actions$: Actions,
    private functionService: FunctionService,
    private messageService: MessageService
  ) {}

  getFunctions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(functionActions.getFunctions),
      mergeMap(({tableOptions}) => {
        return this.functionService.getAllFunctions(tableOptions).pipe(
          map((paginatedResult) => functionActions.getFunctionsSuccessfully({ paginatedResult })),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get functions failed.'
            })
            return of(functionActions.getFunctionsFailed({ error: error.message }))
          })
        )
      })
    )
  )

  createFunction$ = createEffect(() => 
    this.actions$.pipe(
      ofType(functionActions.createFunction),
      mergeMap(({func: func}) => {
        return this.functionService.createFunction(func).pipe(
          map(() => {
            this.messageService.add({
              key: 'toast',
              severity: 'success',
              summary: 'Success',
              detail: 'Function is successfully created'
            })
            return functionActions.createFunctionSuccesfully()
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Function creation failed.',
              detail: error.message
            })
            return of(functionActions.createFunctionFailed(error))
          })
        )
      })
    )
  )

  updateFunction$ = createEffect(() => 
    this.actions$.pipe(
      ofType(functionActions.updateFunction),
      mergeMap(({func: func}) => {
        return this.functionService.updateFunction(func).pipe(
          map(() => {
            this.messageService.add({
              key: 'toast',
              severity: 'success',
              summary: 'Success',
              detail: 'Function is successfully changed'
            })
            return functionActions.updateFunctionSuccesfully()
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Function change failed.',
              detail: error.message
            })
            return of(functionActions.updateFunctionFailed(error))
          })
        )
      })
    )
  )
}
