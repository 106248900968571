export class TpExportsHistoryTableHeaders {
    allColumns = [
        { field: 'eicCode', header: 'EIC', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 160},
        { field: 'creationDate', header: 'Creation Date', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150, cesTime: true},
        { field: 'state', header: 'State', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150 },
        { field: 'result', header: 'Result', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150 },
        { field: 'startDate', header: 'Start Date', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150 },
        { field: 'syncDate', header: 'End Date', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150 },
        { field: 'duration', header: 'Duration', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150 },
        { field: 'errors', header: 'Errors', visible: true, activeStatus: true, sort: false, filter: false, minWidth: 150 },
    ];
}
  