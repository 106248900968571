import { Injectable } from "@angular/core";
import { TableFilteringSortingOptions } from "../types/export-options-columns.interface";

const columnToFilterMapping = {
    requestActionObj: "requestAction",
    statusObj: "status",
    eicCodeTypeObj: "eicCodeType",
    sourceObj: "source"
};

const filterableDateColumns = [
    "requestDate",
    "deactivationDate",
    "approvalDate",
    "creationDate",
    "updateDate",
    "syncDate",
    "startDate"
]

@Injectable({
    providedIn: 'root'
})

export class TableService {

    setFilterPropertyValueForColumn(filterOptions: TableFilteringSortingOptions, columnName: string, value: any): void {
        const filterPropName = columnToFilterMapping[columnName];
        if (filterPropName)
            filterOptions[filterPropName] = value;
        else if (filterOptions[columnName])
            filterOptions[columnName] = value;
    }

    isFilterableDateColumn(filter: string): boolean {
        return filterableDateColumns.includes(filter);
    }
}

