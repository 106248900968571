<div class="p-24px bg-white border-round">
    <div class="flex justify-content-between">
      <h1 class="mb-3 h3 text-secondary">Settings</h1>
    </div>

    <p-button 
    label="Functions" 
    size="large"
    [routerLink]="'functions'"></p-button>
</div>
