export class CioMenuItems {
    menu = [
        {
            label: 'CIO Admin',
            routerLink: '/cio/requests',
            icon: 'icon icon--terminal',
        },
        {
            label: 'All LIOs',
            routerLink: '/lio/all',
            icon: 'icon icon--user',
        },
        {
            label: 'Settings',
            routerLink: '/settings',
            icon: 'icon icon--cog'
        }
    ]
}