import { LioService } from '@app-shared-services/lio.service'
import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, mergeMap, catchError, tap } from 'rxjs/operators'
import * as lioActions from '../actions/lio.actions'
import { MessageService } from 'primeng/api'
import { Router } from '@angular/router'

@Injectable()
export class LioEffect {
  constructor(
    private actions$: Actions,
    private lioService: LioService,
    private messageService: MessageService,
    private router: Router
  ) {}

  public getLioById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lioActions.getLioById),
      mergeMap(({ id }) => {
        return this.lioService.getLioById(id).pipe(
          map((lio) => lioActions.getLioByIdSuccessfully({ lio })),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get lio by id failed.'
            })
            return of(lioActions.getLioByIdFailed(error))
          })
        )
      })
    )
  )

  public getLioByLioNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lioActions.getLioByLioNumber),
      mergeMap(({ lioNumber }) => {
        return this.lioService.getLioByLioNumber(lioNumber).pipe(
          map((lio) => lioActions.getLioByLioNumberSuccessfully({ lio })),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get lio by lio number failed.'
            })
            return of(lioActions.getLioByLioNumberFailed(error))
          })
        )
      })
    )
  )

  getLios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lioActions.getLios),
      mergeMap(({ tableOptions }) => {
        return this.lioService.getLios(tableOptions).pipe(
          map((paginatedResult) => lioActions.getLiosSuccessfully({ paginatedResult })),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get lios failed.'
            })
            return of(lioActions.getLiosFailed({ error: error.message }))
          })
        )
      })
    )
  )

  public createLio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lioActions.create),
      mergeMap((lio) => {
        return this.lioService.createLio(lio).pipe(
          map(() => {
            this.messageService.add({
              key: 'toast',
              severity: 'success',
              summary: 'Success',
              detail: 'LIO added succesfully'
            })
            return lioActions.createSuccessfully()
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'LIO creation failed.'
            })
            return of(lioActions.createFailed(error))
          })
        )
      })
    )
  )

  public createLioSuccesfully$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(lioActions.createSuccessfully),
        tap(() => this.router.navigate(['lio/all']))
      )
    },
    { dispatch: false }
  )

  public updateLio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lioActions.update),
      mergeMap((lio) => {
        return this.lioService.updateLio(lio).pipe(
          map((updatedLio) => {
            this.messageService.add({
              key: 'toast',
              severity: 'success',
              summary: 'Success',
              detail: 'LIO updated succesfully'
            })
            return lioActions.updateSuccessfully({lio: updatedLio})
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'LIO updated failed.'
            })
            return of(lioActions.updateFailed(error))
          })
          )
        })
      )
    )

  public configureSftpForLio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lioActions.configureSftpForLio),
      mergeMap(({sftpConfig}) => {
        return this.lioService.configureSftpForLio(sftpConfig).pipe(
          map(() => {
            this.messageService.add({
              key: 'toast',
              severity: 'success',
              summary: 'Success',
              detail: 'SFTP configuration succesfully saved'
            })
            return lioActions.configureSftpForLioSuccessfully({sftpConfig: sftpConfig})
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to establish SFTP configuration'
            })
            return of(lioActions.configureSftpForLioFailed(error))
          })
        )
      })
    )
  )

public updateSftpConfiguration$ = createEffect(() =>
  this.actions$.pipe(
    ofType(lioActions.updateSftpForLio),
    mergeMap(({sftpConfig}) => {
      return this.lioService.updateSftpConfiguration(sftpConfig).pipe(
        map(() => {
          this.messageService.add({
            key: 'toast',
            severity: 'success',
            summary: 'Success',
            detail: 'SFTP configuration succesfully updated'
          })
          return lioActions.updateSftpForLioSuccessfully({sftpConfig: sftpConfig})
        }),
        catchError((error) => {
          this.messageService.add({
            key: 'toast',
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to update SFTP configuration'
          })
          return of(lioActions.updateSftpForLioFailed(error))
        })
      )
    })
  )
  )

  public deleteLioDoument$ = createEffect(() =>
  this.actions$.pipe(
    ofType(lioActions.deleteLioDocument),
    mergeMap(({documentId}) => {
      return this.lioService.deleteDocumentById(documentId).pipe(
        map((deletedDocument) => {
          this.messageService.add({
            key: 'toast',
            severity: 'success',
            summary: 'Success',
            detail: 'File is successfully deleted'
          })
          return lioActions.deleteLioDocumentSuccessfully({deletedDocument: deletedDocument})
        }),
        catchError((error) => {
          this.messageService.add({
            key: 'toast',
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong, Please try again, If the problem persist contact the service desk'
          })
          return of(lioActions.deleteLioDocumentFailed(error))
        })
      )
    })
  ))
}
