import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, mergeMap, catchError } from 'rxjs/operators'
import * as eicActions from '../actions/eic.actions'
import { MessageService } from 'primeng/api'
import { EICService } from '@app-shared-services/eic.service'
import { GenerateEicException } from '@app/shared/types/generate-eic-exception'

@Injectable()
export class EICEffect {
  constructor(
    private actions$: Actions,
    private eicService: EICService,
    private messageService: MessageService
  ) {}

  getEICs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eicActions.getEICs),
      mergeMap(({ tableOptions }) => {
        return this.eicService.getAllEICs(tableOptions).pipe(
          map((paginatedResult) => eicActions.getEICsSuccessfully({ paginatedResult })),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get EICs failed.'
            })
            return of(eicActions.getEICsFailed({ error: error.message }))
          })
        )
      })
    )
  )

  getEICsOfLio$ = createEffect(() =>
  this.actions$.pipe(
    ofType(eicActions.getEICsOfLio),
    mergeMap(({ lioNumber, tableOptions }) => {
      return this.eicService.getEICsOfLio(lioNumber, tableOptions).pipe(
        map((paginatedResult) => eicActions.getEICsOfLioSuccessfully({ paginatedResult })),
        catchError((error) => {
          this.messageService.add({
            key: 'toast',
            severity: 'error',
            summary: 'Error',
            detail: 'Get EICs of LIO failed.'
          })
          return of(eicActions.getEICsOfLioFailed({ error: error.message }))
        })
      )
    })
  )
)

  generateEIC$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eicActions.generateEIC),
      mergeMap(({ codeType }) => {
        return this.eicService.generateEIC(codeType).pipe(
          map((eic) => eicActions.generateEICSuccessfully({ eic })),
          catchError((error) => {
            let e: GenerateEicException = JSON.parse(error.message);
            error.message = (e != null && e != undefined) ? e.message : "";
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: '',
              detail: error
            })
            return of(eicActions.generateEICFailed({ error: error.message, eicCode: e?.eicCode }))
          })
        )
      })
    )
  )

  generateEicCheckKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eicActions.generateEicCheckKey),
      mergeMap(({ eic }) => {
        return this.eicService.generateEicCheckKey(eic).pipe(
          map((checkKey) => eicActions.generateEicCheckKeySuccessfully({ checkKey })),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: '',
              detail: error
            })
            return of(eicActions.generateEicCheckKeyFailed({ error: error.message }))
          })
        )
      })
    )
  )

  validateEic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eicActions.validateEic),
      mergeMap(({ eic }) => {
        return this.eicService.validateEic(eic).pipe(
          map((isValid) => {
            return eicActions.validateEicSuccessfully({ isValid: JSON.parse(isValid) })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: '',
              detail: error
            })
            return of(eicActions.validateEicFailed({ error: error.message }))
          })
        )
      })
    )
  )
}
