import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component'
import { TintedLayoutComponent } from './layouts/tinted-layout/tinted-layout.component'
import { HomeComponent } from './views/home/home.component'
import { RequestOverviewComponent } from './views/request/request-overview/request-overview.component'
import { CioRequestDetailComponent } from './views/request/cio-request-detail/cio-request-detail.component'
import { LioOverviewComponent } from './views/lio/lio-overview/lio-overview.component'
import { NewLioComponent } from './views/lio/new-lio/new-lio.component'
import { LioDetailComponent } from './views/lio/lio-detail/lio-detail.component'
import { LioRequestOverviewComponent } from './views/lio/lio-request/lio-request-overview/lio-request-overview.component'
import { CioEicOverviewComponent } from './views/cio-eic-overview/cio-eic-overview.component'
import { CioGuard } from './authentication/guards/cio.guard'
import { UnauthorizedComponent } from './authentication/unauthorized/unauthorized.component'
import { CioLioGuard } from './authentication/guards/ciolio.guard'
import { ActiveEicCodesComponent } from './views/reports/active-eic-codes/active-eic-codes.component'
import { EicCodesAllComponent } from './views/reports/eic-codes-all/eic-codes-all.component'
import { DeactivatedCodesComponent } from './views/reports/deactivated-codes/deactivated-codes.component'
import { AllApprovedRequestsComponent } from './views/reports/all-approved-requests/all-approved-requests.component'
import { TpExportsComponent } from './views/exports/tp-exports/tp-exports.component'
import { LioEicOverviewComponent } from './views/lio-eic-overview/lio-eic-overview.component'
import { LioRequestDetailComponent } from './views/request/lio-request-detail/lio-request-detail.component'
import { MaintenanceViewComponent } from './views/maintenance-view/maintenance-view.component'
import { AuthenticationIssueComponent } from './authentication/authentication-issue/authentication-issue.component'
import { SettingsHomeComponent } from './views/settings/settings-home/settings-home.component'
import { FunctionsOverviewComponent } from './views/settings/function/functions-overview/functions-overview.component'

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent
      }
    ]
  },
  {
    path: 'cio/requests',
    component: TintedLayoutComponent,
    children: [
      {
        path: '',
        component: RequestOverviewComponent
      },
      {
        path: ':requestId',
        component: CioRequestDetailComponent
      }
    ]
  },
  {
    path: 'lio',
    component: TintedLayoutComponent,
    children: [
      {
        path: 'all',
        component: LioOverviewComponent,
        canActivate: [CioGuard],
      },
      {
        path: 'new-lio',
        component: NewLioComponent,
        canActivate: [CioGuard],
      },
      {
        path: ':lioNumber/requests',
        component: LioRequestOverviewComponent,
        canActivate: [CioLioGuard],
      },
      {
        path: ':lioNumber/requests/:requestId',
        component: LioRequestDetailComponent,
      },
      {
        path: ':lioNumber/eics',
        children: [
          {
            path: '',
            component: LioEicOverviewComponent
          }
        ]
      },
      {
        path: ':lioNumber',
        component: LioDetailComponent,
        canActivate: [CioLioGuard],
      }
    ]
  },
  {
    path: 'eics',
    component: TintedLayoutComponent,
    children: [
      {
        path: '',
        component: CioEicOverviewComponent
      },
      {
        path: 'requests/:requestId',
        component: CioRequestDetailComponent
      }
    ]
  },
  {
    path: 'reports',
    component: TintedLayoutComponent,
    children: [
      {
        path: 'active-eic-codes',
        component: ActiveEicCodesComponent,
      },
      {
        path: 'all-eic-codes',
        component: EicCodesAllComponent,
      },
      {
        path: 'deactivated-codes',
        component: DeactivatedCodesComponent,
      },
      {
        path: 'all-approved-requests',
        component: AllApprovedRequestsComponent,
      },
    ]
  },
  {
    path: 'exports',
    component: TintedLayoutComponent,
    children: [
      {
        path: 'tp-exports',
        component: TpExportsComponent,
      }
    ]
  },
  {
    path: 'maintenance',
    component: MaintenanceViewComponent
  },
  {
    path: 'authentication-issue',
    component: AuthenticationIssueComponent
  },
  {
    path: 'settings',
    component: TintedLayoutComponent,
    children: [
      {
        path: '',
        component: SettingsHomeComponent,
        canActivate: [CioGuard]
      },
      {
        path: 'functions',
        component: FunctionsOverviewComponent,
        canActivate: [CioGuard]
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
