export const PublicWebsiteUsefulLinks = [
    {label: 'List of EIC codes', value: 'https://www.entsoe.eu/data/energy-identification-codes-eic/eic-approved-codes/'}, 
    {label: 'EIC Reference Manual', value: 'https://www.entsoe.eu/Documents/EDI/Library/EIC_Reference_Manual_Release_5_5.pdf'}, 
    {label: 'EIC Data exchange implementation guide', value: 'https://eepublicdownloads.entsoe.eu/clean-documents/EDI/Library/cim_based/EIC_Data_Exchange_IG_v1.2.pdf'},
    {label: 'Schema EIC', value: 'https://eepublicdownloads.entsoe.eu/clean-documents/EDI/Library/cim_based/schema/EIC_document_uml_model_and_schema_v1.1.pdf'}, 
    {label: 'EIC Check Digit Calculator', value: 'https://www.entsoe.eu/data/energy-identification-codes-eic/code-generator/eic_key_generator'}, 
    {label: 'List of allowed EIC functions', value: 'https://eepublicdownloads.azureedge.net/clean-documents/EDI/Library/List_of_allowed_functions_for_the_EIC_codes.pdf'},
    {label: 'EIC functions definitions', value: 'https://eepublicdownloads.entsoe.eu/clean-documents/EDI/Library/old-downloads/EIC_definitions.pdf'}, 
    {label: 'EIC Attributes', value: 'https://eepublicdownloads.entsoe.eu/clean-documents/EDI/Library/Mandatory_attributes_of_the_EIC_codes.pdf'}, 
    {label: 'All EIC Requests Forms', value: 'https://eepublicdownloads.entsoe.eu/clean-documents/EDI/Library/CIO_Request_Forms.zip'},
    {label: 'EIC Short guide and FAQ', value: 'https://www.entsoe.eu/Documents/EDI/Library/EIC_Short_Guide_and_FAQ_V3_Approved%20April%202016.pdf'}
]