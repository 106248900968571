import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LioInvitationEmail } from '@app/shared/types/lio-invitation-email.class';
import { LioUsersInterface } from '@app/shared/types/lio-users.interface';

@Component({
  selector: 'app-send-invitation',
  templateUrl: './send-invitation.component.html',
  styleUrls: ['./send-invitation.component.scss']
})
export class SendInvitationComponent implements OnInit{
  @Input() selectedRows: LioUsersInterface[];

  @Output("onSendInvite") onSendInvite: EventEmitter<any> = new EventEmitter();

  bcc: string = LioInvitationEmail.Bcc
  subject: string = LioInvitationEmail.Subject
  body: string = LioInvitationEmail.Body

  ngOnInit(): void {}

  sendInvite(){
    const toEmails = this.selectedRows.map(user => user.email).join(',')

    const mailToUrl = `mailto:${toEmails}?bcc=${this.bcc}&subject=${encodeURIComponent(this.subject)}&body=${encodeURIComponent(this.body)}`
    window.location.href = mailToUrl

    this.onSendInvite.emit()
  }

}
