import { SelectItem } from "primeng/api"
import { FunctionStatusObj } from "../function.interface"

export enum FunctionStatusEnum{
    Active = 0,
    Inactive = 1
}

export const functionStatusList: SelectItem[] = [
    {label: "Active", value: 0, icon: 'success'},
    {label: "Inactive", value: 1, icon: 'danger'}
]

export const functionStatusFilterOptions : FunctionStatusObj[] = [
    {label: "Active", value: 0},
    {label: "Inactive", value: 1}
  ]