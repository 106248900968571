import { createAction, props } from '@ngrx/store';
import { FunctionInterface } from '@app/shared/types/function.interface';
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';
import { PaginatedResult } from '@app/shared/types/paginated-result.interface';
import { CreateFunctionCommand } from '@app/shared/types/commands/createFunctionCommand.model';
import { UpdateFunctionCommand } from '@app/shared/types/commands/updateFunctionCommand.model';

export const GET_FUNCTIONS = '[APP Component] Get functions';
export const GET_FUNCTIONS_SUCCESSFUL = '[APP Component] Get functions succes';
export const GET_FUNCTIONS_FAILED = '[APP Component] Get functions failed';

export const CREATE_FUNCTION = '[APP Component] Create function';
export const CREATE_FUNCTION_SUCCESFUL = '[APP Component] Create function succes';
export const CREATE_FUNCTION_FAILED = '[APP Component] Create function failed';

export const UPDATE_FUNCTION = '[APP Component] Update function';
export const UPDATE_FUNCTION_SUCCESFUL = '[APP Component] Update function succes';
export const UPDATE_FUNCTION_FAILED = '[APP Component] Update function failed';

export const getFunctions = createAction(
    GET_FUNCTIONS,
    props<{ tableOptions: TableFilteringSortingOptions | undefined }>()
);

export const getFunctionsSuccessfully = createAction(
    GET_FUNCTIONS_SUCCESSFUL,
    props<{paginatedResult: PaginatedResult<FunctionInterface>}>()
);

export const getFunctionsFailed = createAction(
    GET_FUNCTIONS_FAILED,
    props<{error: string}>()
);

export const createFunction = createAction(
    CREATE_FUNCTION,
    props<{ func: CreateFunctionCommand }>()
);

export const createFunctionSuccesfully = createAction(
    CREATE_FUNCTION_SUCCESFUL
);

export const createFunctionFailed = createAction(
    CREATE_FUNCTION_FAILED,
    props<{error: string}>()
);

export const updateFunction = createAction(
    UPDATE_FUNCTION,
    props<{ func: UpdateFunctionCommand }>()
);

export const updateFunctionSuccesfully = createAction(
    UPDATE_FUNCTION_SUCCESFUL
);

export const updateFunctionFailed = createAction(
    UPDATE_FUNCTION_FAILED,
    props<{error: string}>()
);