export class LioInvitationEmail {
    static Bcc: string = 'cio-admin@entsoe.eu'
    static Subject: string = 'Invitation to access CIO/LIO tool'
    static Body: string = `Hello,

    Please be informed your account has been successfully added to requested LIO on CIO/LIO tool.
    To begin using the CIO/LIO tool, please copy and paste the following URL into your browser: https://cio.entsoe.eu.
    CIO/LIO tool User Guide and other useful documentation is available on Home page named "EIC documents".
    If you have any questions or need assistance, please don't hesitate to reach out CIO administrator.

    Kind regards,
    CIO Administrator`
}