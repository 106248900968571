import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum';
import { TpExportsHistoryInterface } from '@app/shared/types/tp-exports-history.interface';
import { Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as exportsActions from "app/store/actions/history.actions";
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';
import { historySelector, totalRecordsSelector } from '@app/store/selectors/history.selector';
import { TpHistoryStateInterface } from '@app/shared/types/tp-history-state.interface';
import { tpHistoryResult, tpHistoryState } from '@app/shared/types/enums/tp-export-history-selecitems.enum';
import { TableComponent } from '@app/components/table/table.component';

@Component({
  selector: 'app-modal-tp-history',
  templateUrl: './modal-tp-history.component.html',
  styleUrls: ['./modal-tp-history.component.scss']
})

export class ModalTpHistoryComponent implements OnInit {
  visible: boolean = false
  settingType = SettingsTypes.tpExportsHistory;
  history$: Observable<TpExportsHistoryInterface[]>;
  history: TpExportsHistoryInterface[];


  pageNumber: number | undefined = 0
  rowsPerPage: number | undefined = 10

  totalRecords$: Observable<number>
  totalRecords: number
  firstRow: number = 0
  querySub: Subscription
  recordSub: Subscription

  tableFilterOrSortOptions: TableFilteringSortingOptions;
  tpHistoryState = tpHistoryState;
  tpHistoryResult = tpHistoryResult;

  @ViewChild('ht') table: TableComponent;

  constructor(private historyStore: Store<TpHistoryStateInterface>, private actions$: Actions<TpExportsHistoryInterface[]>) {
    this.history$ = this.historyStore.select(historySelector)
    this.totalRecords$ = this.historyStore.pipe(select(totalRecordsSelector))
  }

  ngOnInit(): void {
    
  }

  showDialog(){
    this.tableFilterOrSortOptions = new TableFilteringSortingOptions();

    if (!this.pageNumber || !this.rowsPerPage) {
      this.pageNumber = 0
      this.rowsPerPage = 10
    }

    this.firstRow = this.rowsPerPage * this.pageNumber

    this.recordSub = this.totalRecords$.subscribe((total) => {
      this.totalRecords = total
    })

    this.tableFilterOrSortOptions.pageNumber = this.pageNumber
    this.tableFilterOrSortOptions.rowsPerPage = this.rowsPerPage

    this.visible = true;
    
    this.getData(this.tableFilterOrSortOptions)
  }

  onPageChange(tableFilterOrSortOptions: TableFilteringSortingOptions) {
    this.pageNumber = tableFilterOrSortOptions.pageNumber
    this.rowsPerPage = tableFilterOrSortOptions.rowsPerPage

    let props = tableFilterOrSortOptions
    Object.keys(props).forEach(function (key) {
      if (props[key] == null) {
        delete props[key]
      }
    })
    this.getData(props)
  }

  getData(tableOptions: TableFilteringSortingOptions) {
    this.historyStore.dispatch(exportsActions.getHistory({tableOptions: tableOptions}));
    this.recordSub = this.history$.subscribe((data) => {
      this.history = data.map((item) => {
        let req = Object.assign({}, item)
        return req;
      })
    })
  }

  onClose() {
    this.visible = false;
    this.pageNumber = 0;
    this.rowsPerPage = 10;

    window.location.reload()
  }
}