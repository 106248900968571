import { Component } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { RequestStateInterface } from '@app/shared/types/request-state.interface'
import { RequestInterface } from '@app/shared/types/request.interface'
import { RequestActionEnum } from '@app/shared/types/enums/request-action.enum'
import { Subject, takeUntil } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'
import { requiredIfCodeIsXValidator } from '@app/shared/validators/requiredIfCodeIsXValidator'
import { responsiblePartyValidator } from '@app/shared/validators/responsiblePartyValidator'
import { requestFunctionsValidator } from '@app/shared/validators/requestFunctionsValidator'
import * as RequestActions from '../../../store/actions/request.actions'
import { RequestStatusEnum } from '@app/shared/types/enums/request-status.enum'
import { FunctionInterface } from '@app/shared/types/function.interface'
import { FunctionStateInterface } from '@app/shared/types/function.state.interface'
import { functionsSelector } from '@app/store/selectors/function.selector'
import { CreateRequestCommand } from '@app/shared/types/commands/createRequestCommand.model'
import { UpdateRequestCommand } from '@app/shared/types/commands/updateRequestCommand.model'
import { DatePipe } from '@angular/common'
import { UserInfoStateInterface } from '@app/shared/types/user-info-state.interface'
import { getLioNumber, isLio10 } from '@app/store/selectors/user-info.selector'
import { FunctionStatusEnum } from '@app/shared/types/enums/function-status.enum'
import { MessageService } from 'primeng/api'

@Component({
  selector: 'app-update-request',
  templateUrl: './update-request.component.html',
  styleUrls: ['./update-request.component.scss']
})
export class UpdateRequestComponent {
  request: RequestInterface
  requestForm: FormGroup
  allFunctions: FunctionInterface[]

  visible = false

  RequestStatusEnum = RequestStatusEnum
  destroyed$ = new Subject<boolean>()
  isLio10: boolean = false
  actingLioNumber: number

  title: string
  minDate: Date
  newRequestAction: RequestActionEnum

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<RequestStateInterface>,
    private functionStore: Store<FunctionStateInterface>,
    private updates$: Actions,
    private userStore: Store<UserInfoStateInterface>,
    private datePipe: DatePipe,
    private messageService: MessageService
  ) {
    this.userStore.select(isLio10()).subscribe((usr) => (this.isLio10 = usr))
    this.userStore
      .select(getLioNumber())
      .subscribe((lioNumber) => (this.actingLioNumber = lioNumber))
    this.functionStore.select(functionsSelector).subscribe((fct) => (this.allFunctions = fct))

    this.requestForm = this.formBuilder.group({
      lioNumber: new FormControl({ value: null, disabled: true }, Validators.required),
      eicCodeType: new FormControl({ value: null, disabled: true }, Validators.required),
      eicRandomSequence: new FormControl(
        { value: null, disabled: true },
        Validators.compose([
          Validators.pattern('^[A-Z0-9-]+$'),
          Validators.required,
          Validators.minLength(12),          
          Validators.maxLength(12)
        ])
      ),
      eicCheckKey: new FormControl(
        { value: null, disabled: true },
        Validators.compose([
          Validators.pattern('^[A-Z0-9]+$'),
          Validators.required,
          Validators.maxLength(1)
        ])
      ),
      status: new FormControl({ value: 0, disabled: true }, Validators.required),
      deactivationDate: new FormControl(null),
      acerNumber: new FormControl(
        null,
        Validators.compose([Validators.minLength(12), Validators.maxLength(12),
          Validators.pattern("([A-Za-z0-9_]+\.[A-Z][A-Z])")])
      ),
      euvatCode: new FormControl(
        null,
        Validators.compose([requiredIfCodeIsXValidator, Validators.maxLength(25)])
      ),
      responsibleParty: new FormControl(
        null,
        Validators.compose([
          Validators.minLength(16),
          Validators.maxLength(16),
          responsiblePartyValidator
        ])
      ),
      eicParent: new FormControl(
        null,
        Validators.compose([Validators.minLength(16), Validators.maxLength(16)])
      ),
      eicLongName: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.maxLength(100)])
      ),
      eicDisplayName: new FormControl(
        null,
        Validators.compose([
          Validators.pattern('^[A-Z0-9+_-]+$'),
          Validators.maxLength(16),
          Validators.required
        ])
      ),
      address: new FormControl(
        null,
        this.actingLioNumber == 10
          ? Validators.maxLength(70)
          : null
      ),
      postalCode: new FormControl(
        null,
        this.actingLioNumber == 10
          ? Validators.maxLength(10)
          : null
      ),
      city: new FormControl(
        null,
        this.actingLioNumber == 10
          ? Validators.maxLength(35)
          : null
      ),
      country: new FormControl(
        null,
        this.actingLioNumber == 10
          ? Validators.maxLength(2)
          : null
      ),
      contactDetailsName: new FormControl(
        null,
        this.actingLioNumber == 10
          ? Validators.maxLength(70)
          : null
      ),
      contactDetailsEmail: new FormControl(
        null,
        this.actingLioNumber == 10
          ? Validators.compose([Validators.email, Validators.maxLength(70)])
          : null
      ),
      contactDetailsPhoneNumber: new FormControl(
        null,
        this.actingLioNumber == 10
          ? Validators.compose([
              Validators.pattern('^[+]?[0-9]+$'),
              Validators.maxLength(15)
            ])
          : null
      ),
      functions: this.formBuilder.array([], requestFunctionsValidator),
      description: new FormControl(null, Validators.maxLength(700)),
      hasBeenSubmitted: new FormControl(false, null)
    })
  }

  ngOnInit() {}

  show(request: RequestInterface) {
    this.request = request
    this.visible = true

    if (this.request != null) {
      this.title = request.status == RequestStatusEnum.Pending ? 'Edit request' : 'Request update'

      this.requestForm.patchValue(this.request)

      if (this.request.requestAction == RequestActionEnum.Deactivate && this.request.status == RequestStatusEnum.Pending) {
        this.requestForm.controls['deactivationDate'].setValue(
          new Date(this.request.deactivationDate!)
        )
        this.requestForm.controls['deactivationDate'].setValidators(Validators.required)
      }
      else{
        this.requestForm.controls['deactivationDate'].setValue(null)
      }

      this.initFunctions()
    }
  }

  onSubmit() {
    if (this.request.status == RequestStatusEnum.Pending) {
      // edit a pending request of any type (Create, Update, Deactivation)
      this.newRequestAction = this.request.requestAction
    } else if (this.request.creationDate == null) {
      // updating a request for which an eic code does not exist is still a request to create
      this.newRequestAction = RequestActionEnum.Create
    } else this.newRequestAction = RequestActionEnum.Update

    var selectedFunctions: FunctionInterface[] = []
    this.functions.value.forEach((f) => {
      if (f.isSelected == true)
        selectedFunctions.push(this.allFunctions.find((fct) => fct.id == f.id)!)
    })

    if(this.requestForm.valid){
      if (this.request.status == RequestStatusEnum.Pending) {
        var deactivationDate = this.requestForm.controls['deactivationDate']?.value
          ? new Date(
              this.datePipe.transform(
                this.requestForm.controls['deactivationDate']?.value,
                'yyyy-MM-dd'
              )!
            )
          : undefined
        var updateRequest: UpdateRequestCommand = {
          id: this.request.id!,
          parentRequestId: this.request.parentRequestId,
          lioNumber: this.requestForm.controls['lioNumber'].value,
          eicCodeType: this.requestForm.controls['eicCodeType'].value,
          eicRandomSequence: this.requestForm.controls['eicRandomSequence'].value,
          eicCheckKey: this.requestForm.controls['eicCheckKey'].value,
          eicLongName: this.requestForm.controls['eicLongName'].value,
          eicDisplayName: this.requestForm.controls['eicDisplayName'].value,
          responsibleParty: this.requestForm.controls['responsibleParty'].value != "" ? this.requestForm.controls['responsibleParty'].value : null,
          eicParent: this.requestForm.controls['eicParent'].value != "" ? this.requestForm.controls['eicParent'].value : null,
          euVATCode: this.requestForm.controls['euvatCode'].value != "" ? this.requestForm.controls['euvatCode'].value : null,
          acerNumber: this.requestForm.controls['acerNumber'].value != "" ? this.requestForm.controls['acerNumber'].value : null,
          deactivationDate: deactivationDate,
          address: this.requestForm.controls['address'].value != "" ? this.requestForm.controls['address'].value : null,
          postalCode: this.requestForm.controls['postalCode'].value != "" ? this.requestForm.controls['postalCode'].value : null,
          city: this.requestForm.controls['city'].value != "" ? this.requestForm.controls['city'].value : null,
          country: this.requestForm.controls['country'].value != "" ? this.requestForm.controls['country'].value : null,
          contactDetailsName: this.requestForm.controls['contactDetailsName'].value != "" ? this.requestForm.controls['contactDetailsName'].value : null,
          contactDetailsEmail: this.requestForm.controls['contactDetailsEmail'].value != "" ? this.requestForm.controls['contactDetailsEmail'].value : null,
          contactDetailsPhoneNumber: this.requestForm.controls['contactDetailsPhoneNumber'].value != "" ? this.requestForm.controls['contactDetailsPhoneNumber'].value : null,
          description: this.requestForm.controls['description'].value,
          functions: selectedFunctions.map((item) => item.id)
        }
  
        this.store.dispatch(RequestActions.updateRequest({ request: updateRequest }))
  
        this.updates$
          .pipe(ofType(RequestActions.UPDATE_REQUEST_SUCCESSFUL), takeUntil(this.destroyed$))
          .subscribe(() => {
            this.visible = false
            this.requestForm.reset()
          })
      } else {
        var newRequest: CreateRequestCommand = {
          parentRequestId: this.request.id,
          lioNumber: this.requestForm.controls['lioNumber'].value,
          eicCodeType: this.requestForm.controls['eicCodeType'].value,
          eicRandomSequence: this.requestForm.controls['eicRandomSequence'].value,
          eicCheckKey: this.requestForm.controls['eicCheckKey'].value,
          eicLongName: this.requestForm.controls['eicLongName'].value,
          eicDisplayName: this.requestForm.controls['eicDisplayName'].value,
          responsibleParty: this.requestForm.controls['responsibleParty'].value,
          eicParent: this.requestForm.controls['eicParent'].value,
          euVATCode: this.requestForm.controls['euvatCode'].value,
          acerNumber: this.requestForm.controls['acerNumber'].value,
          requestAction: this.newRequestAction,
          address: this.requestForm.controls['address'].value,
          postalCode: this.requestForm.controls['postalCode'].value,
          city: this.requestForm.controls['city'].value,
          country: this.requestForm.controls['country'].value,
          contactDetailsName: this.requestForm.controls['contactDetailsName'].value,
          contactDetailsEmail: this.requestForm.controls['contactDetailsEmail'].value,
          contactDetailsPhoneNumber: this.requestForm.controls['contactDetailsPhoneNumber'].value,
          description: this.requestForm.controls['description'].value,
          functions: selectedFunctions.map((item) => item.id)
        }
        this.store.dispatch(RequestActions.createRequest({ request: newRequest }))
  
        this.updates$
          .pipe(ofType(RequestActions.CREATE_REQUEST_SUCCESSFUL), takeUntil(this.destroyed$))
          .subscribe(() => {
            this.visible = false
            this.requestForm.reset()
          })
      }
    }
    else{
      // in order to display the validation error messages for the fields
      this.requestForm.controls['hasBeenSubmitted'].setValue(true)
      this.requestForm.controls['functions'].markAllAsTouched()
      this.requestForm.updateValueAndValidity()
      this.messageService.add({
        key: 'toast',
        severity: 'error',
        summary: 'Request is not created',
        detail: 'Check the fields for details'
      })
    }
  }

  initFunctions() {
    this.functions.clear()
    var eligibleFunctions = this.allFunctions
    .filter((f) => f.code == this.requestForm.controls['eicCodeType'].value && (
      f.status == FunctionStatusEnum.Active || this.request.functions.find((fd) => fd.id == f.id)
    ))

    eligibleFunctions.forEach((f) => {
        const functionForm: FormGroup = this.formBuilder.group({
          id: f.id,
          functionName: [f.name + (f.status == FunctionStatusEnum.Inactive ? " (Inactive)" : "")],
          isSelected: this.request.functions.find((fd) => fd.name == f.name) != null ? true : false
        }) as FormGroup
        this.functions.push(functionForm)
      })
  }

  get functions() {
    return this.requestForm.get('functions') as FormArray
  }

  onClose() {
    this.functions.controls = []
    this.visible = false
    this.requestForm.reset()
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
