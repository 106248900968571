import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FunctionStateInterface } from "@app/shared/types/function.state.interface";
import { FunctionStatusEnum } from "@app/shared/types/enums/function-status.enum";

export const functionsSelector = createSelector(
    createFeatureSelector('functions'),
    (state: FunctionStateInterface) => { 
        return state.functions
    }
)

export const activeFunctionsSelector =
  createSelector(functionsSelector, (functions) => functions.filter((f) => f.status == FunctionStatusEnum.Active))


export const isLoadingSelector = createSelector(
    createFeatureSelector('isLoading'),
    (state: FunctionStateInterface) => { 
        return state.isLoading
    }
)
export const errorSelector = createSelector(
    createFeatureSelector('error'),
    (state: FunctionStateInterface) => { 
        return state.error
    }
)
export const totalRecordsSelector = createSelector(
    createFeatureSelector('functions'),
    (state: FunctionStateInterface) => {
        return state.totalRecords
    }
  )