export class FunctionsTableHeaders {
    allColumns = [
        { field: 'eicCodeTypeObj', header: 'Function Code', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 130},
        { field: 'name', header: 'Function Name', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 200},
        { field: 'functionStatusObj', header: 'Status', visible: true, activeStatus: true, sort: false, filter: true , minWidth: 100},
        { field: 'definition', header: 'Definition', visible: true, activeStatus: true, sort: false, filter: true, minWidth: 150 },
        { field: 'creationDate', header: 'Creation Date', dateFormat: "dd-MM-yyyy HH:mm", visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150 },
        { field: 'deactivationDate', header: 'Deactivation Date', dateFormat: "dd-MM-yyyy HH:mm", visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150 },
        { field: 'functionDetail', header: 'Details', visible: true, activeStatus: true, sort: false, filter: false, minWidth: 100 }
    ];
}